@charset "UTF-8";
header#main-header,
footer#main-footer,
.preheader,
.prefooter-campus,
#skipToMenu,
button:not(.accordion-trigger),
.ariane,
.slick-dots,
.tab-control,
.slider-nav-link,
.exergue-deco,
.paragraph--type--video svg,
form,
.svg-wrap,
.fancybox-container,
.pagination,
.related-wrap,
.shareable-outer,
.to-inner,
.modals,
.filters,
.side-inscription,
.side-infos,
.top-elements,
.formation-item-themes,
.page-top-actions {
  display: none !important;
}

.accordion-panel {
  display: block;
}

a:not([data-fancybox])::after {
  content: " ( " attr(href) " ) ";
}

.page-top {
  background: none !important;
}

img {
  max-width: 50%;
}

.slick-slide {
  left: auto !important;
  top: auto !important;
  opacity: 1 !important;
}

table {
  border-top: 1px solid #e8e8e8;
  border-left: 1px solid #e8e8e8;
  text-align: center;
  font-weight: 400;
  border-spacing: 0;
}
table tr th {
  border-bottom: 1px solid #e8e8e8;
  border-right: 1px solid #e8e8e8;
  font-weight: 700;
  padding: 1.3rem 0.7rem;
}
table tr td {
  border-bottom: 1px solid #e8e8e8;
  border-right: 1px solid #e8e8e8;
  padding: 1.3rem 0.7rem;
}
table tr td:first-of-type {
  font-weight: 700;
}

.paragraph--type--video a {
  display: block;
  width: 50%;
  aspect-ratio: 16/9;
}

h1 {
  font-size: 2em;
}

.page-top-inner {
  background: none !important;
}

.accordeon-content {
  height: auto !important;
}

[class$=-item]:not(.single-formation-bloc-body-item):not(.single-formation-bloc-footer-item) {
  margin-top: 30px;
}

[class$=-title] {
  display: block;
}

.single-formation-tarif:before {
  content: "Tarif : ";
}

.single-formation-duree:before {
  content: "Durée : ";
}

.single-formation-bloc-footer-item a {
  display: block;
}